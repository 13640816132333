@import "https://fonts.googleapis.com/css?family=Inter|Roboto%20Slab";
:root {
  --primary-color: #fff;
  --secondary-color: #000;
  --accent1-color: #fe386e;
  --accent2-color: #1fc7ff;
  --heading-font: "Roboto Slab";
  --paragraph-font: Inter, Helvetica, sans-serif;
  --headline-color-on-white: black;
  --headline-color-on-color: white;
  --paragraph-color-on-white: black;
  --paragraph-color-on-color: white;
  --paragraph-background-color: #38dff2;
  --nav-link-color: #fe386e;
  --nav-background-color: #fff;
  --nav-hover-link-color: #fff;
  --nav-hover-background-color: #fe386e;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  color: var(--headline-color-on-white);
}

hr {
  border: 1px solid #0000001a;
  margin: 2em 0 1em;
}

p {
  line-height: 1.5;
}

html {
  background-color: var(--primary-color);
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--paragraph-font);
  background-color: #fff;
  margin: 0;
  padding: 0;
}

a:link, a:visited {
  color: var(--nav-link-color);
}

.lKb-_a_wrap {
  max-width: 960px;
  margin: 0 auto;
}

.lKb-_a_logoBox > a > img {
  height: 42px;
}

.lKb-_a_header {
  height: 72px;
  border-bottom: 2px solid #0000001a;
}

.lKb-_a_footer {
  height: 58px;
  color: var(--nav-link-color);
  border-top: 2px solid #0000001a;
}

.lKb-_a_header .lKb-_a_wrap {
  height: 100%;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  display: grid;
}

.lKb-_a_nav ul {
  text-align: right;
  margin: 0;
  padding: 0;
  list-style: none;
}

.lKb-_a_nav li {
  margin-left: 1em;
  font-size: 22px;
  display: inline-block;
}

.lKb-_a_nav a {
  padding: 1em;
  text-decoration: none;
}

.lKb-_a_nav a:hover {
  color: var(--nav-hover-link-color);
  background-color: var(--nav-hover-background-color);
}

.lKb-_a_footer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.lKb-_a_footer p {
  margin: 0;
}

.lKb-_a_footer a {
  border-bottom: 1px dotted var(--nav-link-color);
  text-decoration: none;
}

.lKb-_a_footer .lKb-_a_rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: sub;
  background: #fff;
  display: inline-block;
}

.lKb-_a_footer .lKb-_a_rsIcon {
  border-bottom: none;
}

@media screen and (max-width: 550px) {
  .lKb-_a_nav ul, .lKb-_a_header .lKb-_a_wrap {
    display: block;
  }

  .lKb-_a_header {
    height: auto;
  }

  .lKb-_a_nav ul {
    text-align: center;
    margin-bottom: 1em;
  }

  .lKb-_a_nav li:first-child {
    margin-left: 0;
  }

  .lKb-_a_nav a {
    padding: 0;
  }

  .lKb-_a_logoBox {
    height: 72px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .lKb-_a_footer {
    height: auto;
    text-align: center;
    padding: 1em;
  }

  .lKb-_a_footer .lKb-_a_wrap {
    width: 70%;
  }
}

.vRzsIW_hero {
  background: var(--paragraph-background-color);
  border-radius: 20px;
  padding: 2em;
}

.vRzsIW_hero p {
  color: var(--paragraph-color-on-color);
  font-family: var(--heading-font);
  margin: 0;
  font-size: 2em;
}

.vRzsIW_layoutGrid {
  grid-template-rows: auto;
  grid-template-columns: repeat(5, 1fr);
  gap: 5em 2em;
  margin: 2em;
  display: grid;
}

.vRzsIW_hero {
  grid-column: 1 / 6;
}

.vRzsIW_featureDescription {
  grid-column: 1 / 4;
  align-self: center;
}

.vRzsIW_featureImage {
  width: 100%;
  grid-column: 4 / 6;
  align-self: center;
}

.vRzsIW_featureDescription:nth-of-type(2n) {
  grid-column: 3 / 6;
}

.vRzsIW_featureImage:nth-of-type(2n) {
  width: 100%;
  grid-column: 1 / 3;
}

.vRzsIW_desc1 {
  order: 0;
}

.vRzsIW_image1 {
  order: 1;
}

.vRzsIW_image2 {
  order: 2;
}

.vRzsIW_desc2 {
  order: 3;
}

.vRzsIW_desc3 {
  order: 4;
}

.vRzsIW_image3 {
  order: 5;
}

.vRzsIW_image4 {
  order: 6;
}

.vRzsIW_desc4 {
  order: 7;
}

.vRzsIW_desc5 {
  order: 8;
}

.vRzsIW_image5 {
  order: 9;
}

@media screen and (max-width: 550px) {
  .vRzsIW_layoutGrid {
    display: block;
  }

  .vRzsIW_hero p {
    font-size: 1.5em;
  }

  .vRzsIW_desc1, .vRzsIW_image1, .vRzsIW_image2, .vRzsIW_desc2, .vRzsIW_desc3, .vRzsIW_image3, .vRzsIW_image4, .vRzsIW_desc4, .vRzsIW_desc5, .vRzsIW_image5 {
    order: 0;
  }

  .vRzsIW_featureImage {
    margin-top: 4em;
  }

  .vRzsIW_featureDescription {
    margin-top: 2em;
  }
}

.VbtPOq_wrap {
  max-width: 960px;
  margin: 0 auto;
}

.VbtPOq_main {
  padding: 2em;
}

/*# sourceMappingURL=index.6a8c4b13.css.map */
