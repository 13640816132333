@import url('https://fonts.googleapis.com/css?family=Inter|Roboto%20Slab');

:root {
  /* change the values below to your colors from your palette */
  --primary-color: #ffffff;
  --secondary-color: #000000;
  --accent1-color: #fe386e;
  --accent2-color: #1fc7ff;

  /* change the values below to your chosen font(s) */
  --heading-font: 'Roboto Slab';
  --paragraph-font: Inter, Helvetica, sans-serif;

  /* these colors below should be chosen from among your palette colors above */
  --headline-color-on-white: black; /* headlines on a white background */
  --headline-color-on-color: white; /* headlines on a colored background */
  --paragraph-color-on-white: black; /* paragraph text on a white background */
  --paragraph-color-on-color: white; /* paragraph text on a colored background */
  --paragraph-background-color: #38dff2;
  --nav-link-color: #fe386e;
  --nav-background-color: #ffffff;
  --nav-hover-link-color: #ffffff;
  --nav-hover-background-color: #fe386e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  color: var(--headline-color-on-white);
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 2em 0 1em 0;
}

p {
  line-height: 1.5;
}

html {
  background-color: var(--primary-color);
  padding: 0;
  margin: 0;
}

body {
  background-color: white;
  font-family: var(--paragraph-font);
  padding: 0;
  margin: 0;
}

a:link,
a:visited {
  color: var(--nav-link-color);
}
